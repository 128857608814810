import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "reactstrap";
import axios from "axios";
import { debounce } from "lodash";
import Icon from "../../adminComponents/icon/Icon";
import styles from "./HeaderSearch.module.css";
import {
  Block,
  PaginationComponent,
  PreviewAltCard,
} from "../../adminComponents/Component";
import { Link } from "react-router-dom";

const Members = (props) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const name = props.name;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(3);

  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getUserDetails = debounce(async () => {
    setLoading(true);
    await axios(
      `${process.env.REACT_APP_DEFAULT_URL}/api/v1/admin/analytics/tracking-data/${name}`
    )
      .then((response) => {
        setUsers(response.data.analyticSingleUserName);        
      })
      .catch((error) => {
        console.log(error.message);
      });
    setLoading(false);
  }, 3000);

  useEffect(() => {
    if (name.length > 0) {
      getUserDetails(name);
    }
  }, [name]);

  return (
    <>
      <Card className="card-full" style={{ background: "transparent" }}>
        {loading && (
          <div className="text-center">
            <Spinner color="dark" />
          </div>
        )}
        <Block>
          <div className="is-separate is-medium mb-3">
            {currentItems.length > 0 &&
              currentItems.map((user) => (
                <>
                  <div className="border-bottom mb-2 pb-2" key={user.userId}>
                    <Row key={user.userId}>
                      <Col md={8} className="pt-2">
                        <strong className={styles.font_16}>{user.userName}</strong>
                      </Col>
                      <Col md={2}>
                        <Link to={"/admin/user-details/" + user.userId}><Button color="warning">
                          <Icon name="eye" />
                          <span>Details</span>
                        </Button>
                        </Link>
                      </Col>
                      <Col md={2}>
                        <Link to={"/admin/user-tracking/" + user.userId}>
                          <Button color="primary">
                            <Icon name="growth" />
                            <span>Analytics</span>
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </>
              ))}
          </div>
          <PreviewAltCard>
            {currentItems.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={users.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No data found</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>
      </Card>
    </>
  );
};

export default Members;
