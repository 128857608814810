import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DropdownToggle, DropdownMenu, Dropdown, Button } from "reactstrap";
import { Icon } from "../../../../adminComponents/Component";
import { LinkList, LinkItem } from "../../../../adminComponents/links/Links";
import UserAvatar from "../../../../adminComponents/user/UserAvatar";
import { setAuthentication } from "../../../../../store/authenticationSlice";
import { logout } from '../../../../../http/index'

const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authenticationSlice);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);

  if (user == null) {
    window.location.href="/admin/login"
  }  

  async function handleSignout() {
    try {
      const { data } = await logout();
      dispatch(setAuthentication(data));
      navigate('/admin/login', { replace: true })
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm" />
          <div className="user-info d-none d-md-block">
            <div className="user-status">Administrator</div>
            <div className="user-name dropdown-indicator">{user.name}</div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>OA</span>
            </div>
            <div className="user-info">
              <span className="lead-text">{user.name}</span>
              <span className="sub-text">{user.email}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="/user-profile-regular" icon="user-alt" onClick={toggle}>
              View Profile
            </LinkItem>
            <LinkItem link="/user-profile-setting" icon="setting-alt" onClick={toggle}>
              Account Setting
            </LinkItem>
            <LinkItem link="/user-profile-activity" icon="activity-alt" onClick={toggle}>
              Login Activity
            </LinkItem>
          </LinkList>
        </div>
        <div className="dropdown-inner p-0 m-0">
          {/* <LinkList className="p-0"> */}
            <Button href="#" onClick={() => handleSignout()} className="btn-primary w-100 p-2">
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </Button>
          {/* </LinkList> */}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
