import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";
import moment from "moment";

export function UseTrackUserActiveTime() {
  const { user } = useSelector((state) => state.authenticationSlice);
  const timeout = 10000;
  let [seconds, setSeconds] = useState(0);
  const [isIdle, setIsIdle] = useState(false);
  const location = useLocation();
  const starttime = moment().format("hh:mm:ss");
  function timer() {
    setInterval(() => {
      if(seconds >= 0){
        setSeconds(seconds++);        
      }else{
        setSeconds(0);
      }      
    }, 1000);
  }

  function clearallIntervals() {
    let interval_id = window.setInterval(() => {}, 99999);
    for (let i = 0; i < interval_id; i++) window.clearInterval(i);
  }

  const handleOnActive = () => {
    console.log("User is active");
    setIsIdle(false);
    setInterval(() => {
      if(seconds >= 0){
        setSeconds(seconds++);
      }else{
        setSeconds(0);
      }      
    }, 1000);
  };
  const handleOnIdle = () => {
    setIsIdle(true);
    console.log("User is idle");    
    setSeconds(seconds);
    let interval_id = window.setInterval(() => {}, 99999);
    for (let i = 0; i < interval_id; i++) window.clearInterval(i);
  };

  const { getTotalActiveTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  async function sendActiveTime() {
    const response = await fetch(`${process.env.REACT_APP_DEFAULT_URL}/api/v1/admin/analytics/send-tracking-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user._id,
        userName: user.name,                    
        date: moment().format("DD-MM-yyyy"),
        pageUrl: location.pathname,
        productName: "Tecentriq",
        pageName: document.title,
        entryTime: starttime,
        exitTime: moment().format("hh:mm:ss"),
        activeTime: seconds,         
      }),
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  useEffect(() => {
    timer();
    return () => {
      setSeconds(seconds);
      clearallIntervals();
      sendActiveTime();      
    };
  }, []);
  // return <p style={{display: "None"}}>seconds</p>;  
  return seconds;
}
