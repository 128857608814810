import React from 'react'
import { Spinner } from 'reactstrap'

const LoadingSpinner = () => {
  return (
    <div className='center_screen'>
        <div className='center_screen_inner'>
            <Spinner style={{ width: "4rem", height: "4rem" }} type="grow" />
        </div>
    </div>
  )
}

export default LoadingSpinner