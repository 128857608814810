// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderSearch_search_results__Xim0k{\r\n    position: absolute;\r\n    top: 65px;\r\n    z-index: 999;\r\n    background:#fff;\r\n    padding:15px;\r\n    box-shadow: 0px 3px 5px #ccc;\r\n}\r\n.HeaderSearch_two_x__89Qs0{\r\n    font-size: 20px;\r\n}\r\n.HeaderSearch_two_x__89Qs0:hover{\r\n    cursor:pointer;\r\n}\r\n.HeaderSearch_font_16__1lgxx{\r\n    font-size: 16px !important;\r\n    font-weight: bolder !important;\r\n}", "",{"version":3,"sources":["webpack://./src/components/adminLayout/Layouts/HeaderSearch.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,4BAA4B;AAChC;AACA;IACI,eAAe;AACnB;AACA;IACI,cAAc;AAClB;AACA;IACI,0BAA0B;IAC1B,8BAA8B;AAClC","sourcesContent":[".search_results{\r\n    position: absolute;\r\n    top: 65px;\r\n    z-index: 999;\r\n    background:#fff;\r\n    padding:15px;\r\n    box-shadow: 0px 3px 5px #ccc;\r\n}\r\n.two_x{\r\n    font-size: 20px;\r\n}\r\n.two_x:hover{\r\n    cursor:pointer;\r\n}\r\n.font_16{\r\n    font-size: 16px !important;\r\n    font-weight: bolder !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search_results": "HeaderSearch_search_results__Xim0k",
	"two_x": "HeaderSearch_two_x__89Qs0",
	"font_16": "HeaderSearch_font_16__1lgxx"
};
export default ___CSS_LOADER_EXPORT___;
