import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // useLocation,
  Outlet,  
} from "react-router-dom";
import { useLoadingWithRefresh } from "./hooks/useLoadingWithRefresh";
import UseAdminState from './adminState/useAdminState';
import LoadingSpinner from "./components/adminContent/LoadingSpinner";
import AdminSlider from "./components/adminPages/AdminSlider";
// import AdminSingleDetail from "./components/adminPages/AdminSingleDetail";

// Admin
const AdminLogin = lazy(() =>
  import("./components/adminPages/authentication/AdminLogin")
);
const AdminDashboard = lazy(() =>
  import("./components/adminPages/AdminDashboard")
);
const AdminHCPMembers = lazy(() =>
  import("./components/adminPages/AdminHCPMembers")
);
const AdminRocheMembers = lazy(() =>
  import("./components/adminPages/AdminRocheMembers")
);
const AdminAllMembers = lazy(() =>
  import("./components/adminPages/AdminAllMembers")
);
const AdminUsersSurvey = lazy(() =>
  import("./components/adminPages/AdminUsersSurvey")
);
const AdminUsersSingleSurvey = lazy(() =>
  import("./components/adminPages/AdminUsersSingleSurvey")
);
const AdminArticles = lazy(() =>
  import("./components/adminPages/AdminArticles")
);
const AdminArticlesTags = lazy(() =>
  import("./components/adminPages/AdminArticlesTags")
);
const AdminArticlesCategory = lazy(() =>
  import("./components/adminPages/AdminArticlesCategory")
);
const AdminPdfAccess = lazy(() =>
  import("./components/adminPages/AdminPdfAccess")
);
const AdminTracking = lazy(() =>
  import("./components/adminPages/AdminTracking")
);
const AdminOverallTracking = lazy(() =>
  import("./components/adminPages/AdminOverallTracking")
);
const AdminUsersSingleAnalytics = lazy(() =>
  import("./components/adminPages/AdminUsersSingleAnalytics")
);
const AdminForgotPassword = lazy(() =>
  import("./components/adminPages/authentication/AdminForgotPassword")
);
const AdminCreatePassword = lazy(() =>
  import("./components/adminPages/authentication/AdminCreatePassword")
);
const AdminSingleDetail = lazy(() =>
  import("./components/adminPages/AdminSingleDetail")
);

function App() {  
  const { loading } = useLoadingWithRefresh();
  
  return loading ? (
    <LoadingSpinner />
  ) : (
    <UseAdminState>
      <Router>
        <Suspense fallback={<></>}>
          <Routes>
            <Route element={<RequireAuth />}>
            <>
              <Route path="admin/login" element={<AdminLogin />} />
              <Route path="/" element={<AdminLogin />} />
              <Route path="admin/dashboard" element={<AdminDashboard />} />
              <Route path="admin/all-members" element={<AdminAllMembers />} />
              <Route path="admin/hcp-members" element={<AdminHCPMembers />} />
              <Route path="admin/roche-members" element={<AdminRocheMembers />} />
              <Route path="admin/surveys" element={<AdminUsersSurvey />} />
              <Route
                path="admin/surveys/:code"
                element={<AdminUsersSingleSurvey />}
              />
              <Route path="admin/articles" element={<AdminArticles />} />
              <Route
                path="admin/articles/tags/:tags"
                element={<AdminArticlesTags />}
              />
              <Route
                path="admin/articles/category/:category"
                element={<AdminArticlesCategory />}
              />
              <Route path="admin/pdf-access" element={<AdminPdfAccess />} />
              <Route
                path="admin/pdf-access/:accessToken/:adminId/:id"
                element={<AdminPdfAccess />}
              />
              <Route path="admin/user-tracking" element={<AdminTracking />} />
              <Route
                path="admin/overall-tracking"
                element={<AdminOverallTracking />}
              />
              <Route
                path="admin/user-tracking/:userId"
                element={<AdminUsersSingleAnalytics />}
              />
              <Route path="admin/user-details/:userId" element={<AdminSingleDetail />} />
              <Route path="admin/forgot-password" element={<AdminForgotPassword />} />
              <Route path="admin/create-password/:accessToken/:email" element={<AdminCreatePassword />} />

              <Route path="admin/slider" element={<AdminSlider />} />
            </>
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </UseAdminState>
  );
}

function RequireAuth() {
  const { isAuth } = useSelector((state) => state.authenticationSlice);
  // let location = useLocation();
  if (!isAuth) {
    // return <Navigate to="/admin/login" state={{ from: location }} />;
  }

  return <Outlet />;
}

export default App;
