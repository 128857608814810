import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { setAuthentication } from "../store/authenticationSlice";
import { refresh } from "../http";

export function useLoadingWithRefresh() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await refresh();

        console.log("useLoadingWithRefresh: " + data);
        dispatch(setAuthentication(data));
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    })();
  }, []);

  return { loading };
}
