import React, { useEffect, useState } from "react";
import Content from "../adminLayout/Layouts/Content";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  RSelect,
} from "../adminComponents/Component";
import {
  Alert,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Modal,
  ModalBody,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import axios from "axios";
import SliderMainData from "./SliderMainData";
import {fontelloIcons} from '../../constants/fontello';

const SliderMain = () => {
  const filterStatus = [
    { value: "Draft", label: "Draft" },
    { value: "Pending", label: "Pending" },
    { value: "Published", label: "Published" },
    { value: "Deleted", label: "Deleted" },
  ];
  //Sidebar
  const [sm, updateSm] = useState(false);
  // const [file, setFile] = useState();
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [defaultFiles, setDefaultFiles] = useState("");
  const [itemCount, setItemCount] = useState(0);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [modalimage, setModalImage] = useState({
    view: false,
  });
  const [formData, setFormData] = useState({
    title: "",
    slug: "",
    buttonText: "",
    imageSource: "",
    imageName: "",
    ctaIcon: "",
    status: "",
  });

  // function to reset the form
  const resetForm = () => {
    setDefaultFiles("");
    setFormData({
      title: "",
      slug: "",
      buttonText: "",
      imageSource: "",
      imageName: "",
      ctaIcon: "",
      status: "",
    });
    setMessage("");
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };
  const onImagePopupCancel = () => {
    setModalImage({ view: false });
  };

  async function UploadImage(e) {
    // setFile(e.target.files[0]);
    setDefaultFiles(e.target.files[0].name);
    const imageData = new FormData();
    imageData.append("file", e.target.files[0]);
    imageData.append("upload_preset", "kcpsl7kn");
    imageData.append("folder", "abhinav_tour");
    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/solanki1969/image/upload`,
        imageData,
        {
          ...formData,
          imageData,
        }
      );
      if (res.status === 200 && res.data !== "") {
        console.log(res.data);
        setFormData({
          ...formData,
          imageSource: res.data.url,
          imageName: res.data.original_filename,
        });
        // setFormData({ ...formData, filename: res.data.filename, image: res.data.filename, originalname: res.data.originalname });
        setModalImage({ view: true });
        setMessage("Image Uploaded Successfully");
        setDefaultFiles("");
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  async function submitSlider() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_DEFAULT_URL}/api/v1/admin/create-slider`,
        formData
      );      
      if (res.data.status === 200) {
        setResponseMessage(res.data.message);
        setTimeout(() => {
          resetForm();
        }, 1000);
      } else {
        setResponseMessage(res.data.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async function getSliderMainData() {
    let url = `api/v1/admin/slider/show`;

    await fetch(`${process.env.REACT_APP_DEFAULT_URL}/${url}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((res) => {
        setItemCount(res.slider);
      });
  }

  useEffect(()=> {
    getSliderMainData();
  }, []);

  return (
    <React.Fragment>
      <Content>
        <Block>
          <Row className="g-gs">
            <Col xxl="6" sm="12">
              {responseMessage && (
                <>
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" /> {responseMessage}{" "}
                  </Alert>
                </>
              )}
              <div className="p-2">
                <h3 className="titles">Add New Slider</h3>

                <div className="mt-4">
                  <Form className="row gy-4">
                    <Col md="12">
                      <div className="mb-0">
                        <label className="form-label">Title</label>
                        <input
                          className="form-control"
                          type="text"
                          name="title"
                          defaultValue={formData.title}
                          placeholder="Enter title"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              slug: e.target.value
                                .replace(/\s+/g, "-")
                                .toLowerCase(),
                              title: e.target.value,
                            });
                          }}
                          autoComplete="off"
                        />
                        <input
                          className="form-control"
                          type="hidden"
                          name="slug"
                          defaultValue={formData.slug}
                          readOnly
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-0">
                        <label className="form-label">Source</label>
                        <input
                          className="form-control"
                          type="text"
                          name="imageSource"
                          defaultValue={formData.imageSource}
                          placeholder="Enter Image Source"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              imageSource: e.target.value,
                            });
                          }}
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-0">
                        <label className="form-label">Button Text</label>
                        <input
                          className="form-control"
                          type="text"
                          name="buttonText"
                          defaultValue={formData.buttonText}
                          placeholder="Enter Button Text"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              buttonText: e.target.value,
                            });
                          }}
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-0">
                        <label className="form-label">Status</label>
                        <div className="form-control-wrap">
                          <RSelect
                            options={filterStatus}
                            defaultValue={{ value: "", label: "Select" }}
                            onChange={(e) =>
                              setFormData({ ...formData, status: e.value })
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-0">
                        <label className="form-label">Button Font Icon</label>
                        <div className="form-control-wrap">
                          <RSelect
                            options={fontelloIcons}
                            defaultValue={{ value: "", label: "Select" }}
                            onChange={(e) =>
                              setFormData({ ...formData, status: e.value })
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-0">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          name="file"
                          onChange={(e) => {
                            UploadImage(e);
                            // setTimeout(() => UploadImage(e), 1000);
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          {defaultFiles === "" ? "Choose files" : defaultFiles}
                        </label>
                        {message && (
                          <>
                            <span className="badge badge-outline-success">
                              {message}
                            </span>
                            <Button
                              color="warning"
                              className="ml-3 px-1 btn-icon"
                              onClick={() => setModalImage({ view: true })}
                            >
                              <Icon name="eye"></Icon> View Image
                            </Button>
                          </>
                        )}
                      </div>
                    </Col>
                    
                    <Col size="12" className="pr-0">
                    {itemCount.length >= 2 ? (
                      <Alert color="danger" className="alert-icon">
                        <h4><Icon name="alert-circle" /> You have already reached maximum number of slides</h4>
                      </Alert>
                      ) : (                        
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            color="primary"
                            size="md"
                            onClick={submitSlider}
                            disabled={itemCount.length >= 2 && true}
                          >
                            {itemCount.length >= 2 ? <span><Icon name="cross-circle-fill"></Icon> Maximum Sliders</span> : <span><Icon name="check"></Icon> Add Slider</span>}
                          </Button>
                        </li>
                      </ul>
                      )}
                    </Col>
                    
                  </Form>
                </div>
              </div>{" "}
            </Col>
            <Col xxl="6" sm="12">
              <BlockHead size="sm">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle page tag="h3">
                      All Sliders
                    </BlockTitle>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <Button
                        className={`btn-icon btn-trigger toggle-expand mr-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => updateSm(!sm)}
                      >
                        <Icon name="more-v" />
                      </Button>
                      <div
                        className="toggle-expand-content"
                        style={{ display: sm ? "block" : "none" }}
                      >
                        <ul className="nk-block-tools g-3">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                              >
                                <Icon
                                  className="d-none d-sm-inline"
                                  name="calender-date"
                                />
                                <span>
                                  <span className="d-none d-md-inline">
                                    Last
                                  </span>{" "}
                                  30 Days
                                </span>
                                <Icon
                                  className="dd-indc"
                                  name="chevron-right"
                                />
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                      href="#!"
                                    >
                                      <span>Last 30 days</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                      href="#dropdownitem"
                                    >
                                      <span>Last 6 months</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                      href="#dropdownitem"
                                    >
                                      <span>Last 3 weeks</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                          <li className="nk-block-tools-opt">
                            <Button color="primary">
                              <Icon name="reports" />
                              <span>Reports</span>
                            </Button>
                          </li>
                          <li className="nk-block-tools-opt">
                            <Button
                              color="primary"
                              className="btn-icon"
                              onClick={() => setModal({ add: true })}
                            >
                              <Icon name="plus"></Icon>
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block>
                <Row className="g-gs">
                  <Col xxl="12">
                    <SliderMainData />
                  </Col>
                </Row>
              </Block>
            </Col>
          </Row>
        </Block>
      </Content>
      <Modal
        isOpen={modalimage.view}
        toggle={() => setModalImage({ view: false })}
        className="modal-dialog-centered"
        size="xl"
      >
        <ModalBody>
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onImagePopupCancel();
            }}
            className="close"
          >
            <Icon name="cross"></Icon>
          </a>
          <div className="p-2">
            <h3 className="titles">{formData.imageName}</h3>
            <div className="mt-4">
              <img
                src={`${formData.imageSource}`}
                alt={formData.imageName}
                className="img-fluid w-100"
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default SliderMain;
