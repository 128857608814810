export const fontelloIcons = [
    {value: "icon-vcard", label: "icon-vcard"},
    {value: "icon-pin", label: "icon-pin"},
    {value: "icon-reply", label: "icon-reply"},
    {value: "icon-reply-all", label: "icon-reply-all"},
    {value: "icon-forward", label: "icon-forward"},
    {value: "icon-print", label: "icon-print"},
    {value: "icon-keyboard", label: "icon-keyboard"},
    {value: "icon-slider-left", label: "icon-slider-left"},
    {value: "icon-clock-empty", label: "icon-clock-empty"},
    {value: "icon-hourglass", label: "icon-hourglass"},
    {value: "icon-login", label: "icon-login"},
    {value: "icon-logout", label: "icon-logout"},
    {value: "icon-picture", label: "icon-picture"},
    {value: "icon-brush", label: "icon-brush"},
    {value: "icon-ccw", label: "icon-ccw"},
    {value: "icon-cw", label: "icon-cw"},
    {value: "icon-refresh", label: "icon-refresh"},
    {value: "icon-clock", label: "icon-clock"},
    {value: "icon-circle-empty", label: "icon-circle-empty"},
    {value: "icon-circle-dot", label: "icon-circle-dot"},
    {value: "icon-circle", label: "icon-circle"},
    {value: "icon-checkbox", label: "icon-checkbox"},
    {value: "icon-checkbox-empty", label: "icon-checkbox-empty"},
    {value: "icon-book", label: "icon-book"},
    {value: "icon-mail-empty", label: "icon-mail-empty"},
    {value: "icon-mail", label: "icon-mail"},
    {value: "icon-phone", label: "icon-phone"},
    {value: "icon-book-open", label: "icon-book-open"},
    {value: "icon-users-group", label: "icon-users-group"},
    {value: "icon-male", label: "icon-male"},
    {value: "icon-female", label: "icon-female"},
    {value: "icon-child", label: "icon-child"},
    {value: "icon-star", label: "icon-star"},
    {value: "icon-globe", label: "icon-globe"},
    {value: "icon-mobile", label: "icon-mobile"},
    {value: "icon-tablet-1", label: "icon-tablet-1"},
    {value: "icon-laptop", label: "icon-laptop"},
    {value: "icon-desktop", label: "icon-desktop"},
    {value: "icon-cog", label: "icon-cog"},
    {value: "icon-cogs", label: "icon-cogs"},
    {value: "icon-down", label: "icon-down"},
    {value: "icon-wrench", label: "icon-wrench"},
    {value: "icon-sliders", label: "icon-sliders"},
    {value: "icon-lock", label: "icon-lock"},
    {value: "icon-trophy", label: "icon-trophy"},
    {value: "icon-spin3", label: "icon-spin3"},
    {value: "icon-spin1", label: "icon-spin1"},
    {value: "icon-spin2", label: "icon-spin2"},
    {value: "icon-spin4", label: "icon-spin4"},
    {value: "icon-spin5", label: "icon-spin5"},
    {value: "icon-spin6", label: "icon-spin6"},
    {value: "icon-location", label: "icon-location"},
    {value: "icon-address", label: "icon-address"},
    {value: "icon-up", label: "icon-up"},
    {value: "icon-right", label: "icon-right"},
    {value: "icon-left", label: "icon-left"},
    {value: "icon-music-light", label: "icon-music-light"},
    {value: "icon-search-light", label: "icon-search-light"},
    {value: "icon-mail-light", label: "icon-mail-light"},
    {value: "icon-heart-light", label: "icon-heart-light"},
    {value: "icon-dot", label: "icon-dot"},
    {value: "icon-user-light", label: "icon-user-light"},
    {value: "icon-video-light", label: "icon-video-light"},
    {value: "icon-camera-light", label: "icon-camera-light"},
    {value: "icon-photo-light", label: "icon-photo-light"},
    {value: "icon-attach-light", label: "icon-attach-light"},
    {value: "icon-lock-light", label: "icon-lock-light"},
    {value: "icon-eye-light", label: "icon-eye-light"},
    {value: "icon-tag-light", label: "icon-tag-light"},
    {value: "icon-thumbs-up-light", label: "icon-thumbs-up-light"},
    {value: "icon-pencil-light", label: "icon-pencil-light"},
    {value: "icon-comment-light", label: "icon-comment-light"},
    {value: "icon-location-light", label: "icon-location-light"},
    {value: "icon-cup-light", label: "icon-cup-light"},
    {value: "icon-trash-light", label: "icon-trash-light"},
    {value: "icon-doc-light", label: "icon-doc-light"},
    {value: "icon-key-light", label: "icon-key-light"},
    {value: "icon-database-light", label: "icon-database-light"},
    {value: "icon-megaphone-light", label: "icon-megaphone-light"},
    {value: "icon-graduation-light", label: "icon-graduation-light"},
    {value: "icon-fire-light", label: "icon-fire-light"},
    {value: "icon-paper-plane-light", label: "icon-paper-plane-light"},
    {value: "icon-cloud-light", label: "icon-cloud-light"},
    {value: "icon-globe-light", label: "icon-globe-light"},
    {value: "icon-inbox-light", label: "icon-inbox-light"},
    {value: "icon-cart", label: "icon-cart"},
    {value: "icon-resize-small", label: "icon-resize-small"},
    {value: "icon-desktop-light", label: "icon-desktop-light"},
    {value: "icon-tv-light", label: "icon-tv-light"},
    {value: "icon-wallet-light", label: "icon-wallet-light"},
    {value: "icon-t-shirt-light", label: "icon-t-shirt-light"},
    {value: "icon-lightbulb-light", label: "icon-lightbulb-light"},
    {value: "icon-clock-light", label: "icon-clock-light"},
    {value: "icon-diamond-light", label: "icon-diamond-light"},
    {value: "icon-shop-light", label: "icon-shop-light"},
    {value: "icon-sound-light", label: "icon-sound-light"},
    {value: "icon-calendar-light", label: "icon-calendar-light"},
    {value: "icon-food-light", label: "icon-food-light"},
    {value: "icon-money-light", label: "icon-money-light"},
    {value: "icon-params-light", label: "icon-params-light"},
    {value: "icon-cog-light", label: "icon-cog-light"},
    {value: "icon-beaker-light", label: "icon-beaker-light"},
    {value: "icon-note-light", label: "icon-note-light"},
    {value: "icon-truck-light", label: "icon-truck-light"},
    {value: "icon-behance", label: "icon-behance"},
    {value: "icon-bitbucket", label: "icon-bitbucket"},
    {value: "icon-cc", label: "icon-cc"},
    {value: "icon-codeopen", label: "icon-codeopen"},
    {value: "icon-css3", label: "icon-css3"},
    {value: "icon-delicious", label: "icon-delicious"},
    {value: "icon-digg", label: "icon-digg"},
    {value: "icon-dribbble", label: "icon-dribbble"},
    {value: "icon-deviantart", label: "icon-deviantart"},
    {value: "icon-dropbox", label: "icon-dropbox"},
    {value: "icon-drupal", label: "icon-drupal"},
    {value: "icon-facebook", label: "icon-facebook"},
    {value: "icon-flickr", label: "icon-flickr"},
    {value: "icon-foursquare", label: "icon-foursquare"},
    {value: "icon-git", label: "icon-git"},
    {value: "icon-github", label: "icon-github"},
    {value: "icon-gittip", label: "icon-gittip"},
    {value: "icon-google", label: "icon-google"},
    {value: "icon-gplus", label: "icon-gplus"},
    {value: "icon-gwallet", label: "icon-gwallet"},
    {value: "icon-hacker-news", label: "icon-hacker-news"},
    {value: "icon-html5", label: "icon-html5"},
    {value: "icon-instagramm", label: "icon-instagramm"},
    {value: "icon-ioxhost", label: "icon-ioxhost"},
    {value: "icon-joomla", label: "icon-joomla"},
    {value: "icon-jsfiddle", label: "icon-jsfiddle"},
    {value: "icon-lastfm", label: "icon-lastfm"},
    {value: "icon-linux", label: "icon-linux"},
    {value: "icon-linkedin", label: "icon-linkedin"},
    {value: "icon-maxcdn", label: "icon-maxcdn"},
    {value: "icon-meanpath", label: "icon-meanpath"},
    {value: "icon-openid", label: "icon-openid"},
    {value: "icon-pagelines", label: "icon-pagelines"},
    {value: "icon-paypal", label: "icon-paypal"},
    {value: "icon-qq", label: "icon-qq"},
    {value: "icon-reddit", label: "icon-reddit"},
    {value: "icon-renren", label: "icon-renren"},
    {value: "icon-skype", label: "icon-skype"},
    {value: "icon-slack", label: "icon-slack"},
    {value: "icon-slideshare", label: "icon-slideshare"},
    {value: "icon-soundcloud", label: "icon-soundcloud"},
    {value: "icon-spotify", label: "icon-spotify"},
    {value: "icon-stackexchange", label: "icon-stackexchange"},
    {value: "icon-stackoverflow", label: "icon-stackoverflow"},
    {value: "icon-steam", label: "icon-steam"},
    {value: "icon-stumbleupon", label: "icon-stumbleupon"},
    {value: "icon-tencent-weibo", label: "icon-tencent-weibo"},
    {value: "icon-trello", label: "icon-trello"},
    {value: "icon-tumblr", label: "icon-tumblr"},
    {value: "icon-twitch", label: "icon-twitch"},
    {value: "icon-twitter", label: "icon-twitter"},
    {value: "icon-vine", label: "icon-vine"},
    {value: "icon-vkontakte", label: "icon-vkontakte"},
    {value: "icon-wechat", label: "icon-wechat"},
    {value: "icon-weibo", label: "icon-weibo"},
    {value: "icon-windows", label: "icon-windows"},
    {value: "icon-wordpress", label: "icon-wordpress"},
    {value: "icon-xing", label: "icon-xing"},
    {value: "icon-yelp", label: "icon-yelp"},
    {value: "icon-youtube", label: "icon-youtube"},
    {value: "icon-yahoo", label: "icon-yahoo"},
    {value: "icon-lemon", label: "icon-lemon"},
    {value: "icon-blank", label: "icon-blank"},
    {value: "icon-pinterest-circled", label: "icon-pinterest-circled"},
    {value: "icon-heart-empty", label: "icon-heart-empty"},
    {value: "icon-lock-open", label: "icon-lock-open"},
    {value: "icon-heart", label: "icon-heart"},
    {value: "icon-check", label: "icon-check"},
    {value: "icon-cancel", label: "icon-cancel"},
    {value: "icon-plus", label: "icon-plus"},
    {value: "icon-minus", label: "icon-minus"},
    {value: "icon-double-left", label: "icon-double-left"},
    {value: "icon-double-right", label: "icon-double-right"},
    {value: "icon-double-up", label: "icon-double-up"},
    {value: "icon-double-down", label: "icon-double-down"},
    {value: "icon-help", label: "icon-help"},
    {value: "icon-info", label: "icon-info"},
    {value: "icon-link", label: "icon-link"},
    {value: "icon-quote", label: "icon-quote"},
    {value: "icon-attach", label: "icon-attach"},
    {value: "icon-eye", label: "icon-eye"},
    {value: "icon-tag", label: "icon-tag"},
    {value: "icon-menu", label: "icon-menu"},
    {value: "icon-pencil", label: "icon-pencil"},
    {value: "icon-feather", label: "icon-feather"},
    {value: "icon-code", label: "icon-code"},
    {value: "icon-home", label: "icon-home"},
    {value: "icon-camera", label: "icon-camera"},
    {value: "icon-pictures", label: "icon-pictures"},
    {value: "icon-video", label: "icon-video"},
    {value: "icon-users", label: "icon-users"},
    {value: "icon-user", label: "icon-user"},
    {value: "icon-user-add", label: "icon-user-add"},
    {value: "icon-comment-empty", label: "icon-comment-empty"},
    {value: "icon-chat-empty", label: "icon-chat-empty"},
    {value: "icon-bell", label: "icon-bell"},
    {value: "icon-star-empty", label: "icon-star-empty"},
    {value: "icon-dot2", label: "icon-dot2"},
    {value: "icon-dot3", label: "icon-dot3"},
    {value: "icon-note", label: "icon-note"},
    {value: "icon-basket", label: "icon-basket"},
    {value: "icon-note2", label: "icon-note2"},
    {value: "icon-share", label: "icon-share"},
    {value: "icon-doc", label: "icon-doc"},
    {value: "icon-doc-text", label: "icon-doc-text"},
    {value: "icon-file-pdf", label: "icon-file-pdf"},
    {value: "icon-file-word", label: "icon-file-word"},
    {value: "icon-file-excel", label: "icon-file-excel"},
    {value: "icon-file-powerpoint", label: "icon-file-powerpoint"},
    {value: "icon-file-image", label: "icon-file-image"},
    {value: "icon-file-archive", label: "icon-file-archive"},
    {value: "icon-file-audio", label: "icon-file-audio"},
    {value: "icon-file-video", label: "icon-file-video"},
    {value: "icon-file-code", label: "icon-file-code"},
    {value: "icon-trash-empty", label: "icon-trash-empty"},
    {value: "icon-trash", label: "icon-trash"},
    {value: "icon-th-large", label: "icon-th-large"},
    {value: "icon-th", label: "icon-th"},
    {value: "icon-th-list", label: "icon-th-list"},
    {value: "icon-flag", label: "icon-flag"},
    {value: "icon-attention", label: "icon-attention"},
    {value: "icon-play", label: "icon-play"},
    {value: "icon-stop", label: "icon-stop"},
    {value: "icon-pause", label: "icon-pause"},
    {value: "icon-record", label: "icon-record"},
    {value: "icon-to-end", label: "icon-to-end"},
    {value: "icon-tools", label: "icon-tools"},
    {value: "icon-retweet", label: "icon-retweet"},
    {value: "icon-to-start", label: "icon-to-start"},
    {value: "icon-fast-forward", label: "icon-fast-forward"},
    {value: "icon-fast-backward", label: "icon-fast-backward"},
    {value: "icon-search", label: "icon-search"},
    {value: "icon-slider-right", label: "icon-slider-right"},
    {value: "icon-rss", label: "icon-rss"},
    {value: "icon-down-big-1", label: "icon-down-big-1"},
    {value: "icon-left-big-1", label: "icon-left-big-1"},
    {value: "icon-right-big-1", label: "icon-right-big-1"},
    {value: "icon-up-big-1", label: "icon-up-big-1"},
    {value: "icon-comment-2", label: "icon-comment-2"},
    {value: "icon-comment", label: "icon-comment"},
    {value: "icon-eye-2", label: "icon-eye-2"},
    {value: "icon-tablet", label: "icon-tablet"},
    {value: "icon-bathtub", label: "icon-bathtub"},
    {value: "icon-comb", label: "icon-comb"},
    {value: "icon-calendar", label: "icon-calendar"},
    {value: "icon-search-alt", label: "icon-search-alt"},
    {value: "icon-white", label: "icon-white"},
    {value: "icon-home-alt", label: "icon-home-alt"},
    {value: "icon-timer", label: "icon-timer"},
    {value: "icon-locator", label: "icon-locator"},
    {value: "icon-cup", label: "icon-cup"},
    {value: "icon-currency", label: "icon-currency"},
    {value: "icon-box", label: "icon-box"},
    {value: "icon-quote-left", label: "icon-quote-left"},
    {value: "icon-quote-right", label: "icon-quote-right"},
    {value: "icon-volume-up", label: "icon-volume-up"},
    {value: "icon-volume-off", label: "icon-volume-off"},
    {value: "icon-volume", label: "icon-volume"},
    {value: "icon-volume-down", label: "icon-volume-down"},
    {value: "icon-arrow-left-alt", label: "icon-arrow-left-alt"},
    {value: "icon-arrow-right-alt", label: "icon-arrow-right-alt"},
    {value: "icon-thumbs-up", label: "icon-thumbs-up"},
    {value: "icon-thumbs-down", label: "icon-thumbs-down"},
    {value: "icon-speech", label: "icon-speech"},
    {value: "icon-sphere", label: "icon-sphere"},
    {value: "icon-light", label: "icon-light"},
    {value: "icon-apple-mouse", label: "icon-apple-mouse"},
    {value: "icon-arrow-down", label: "icon-arrow-down"},
    {value: "icon-arrow-left", label: "icon-arrow-left"},
    {value: "icon-arrow-right", label: "icon-arrow-right"},
    {value: "icon-arrow-up", label: "icon-arrow-up"},
    {value: "icon-search-2", label: "icon-search-2"},
    {value: "icon-cd-light", label: "icon-cd-light"},
    {value: "icon-mobile-light", label: "icon-mobile-light"},
    {value: "icon-clear-button", label: "icon-clear-button"},
    {value: "icon-binoculars", label: "icon-binoculars"},
    {value: "icon-resize-full", label: "icon-resize-full"},
    {value: "icon-truck", label: "icon-truck"},
    {value: "icon-clipboard", label: "icon-clipboard"},
    {value: "icon-email", label: "icon-email"},
    {value: "icon-modem", label: "icon-modem"},
    {value: "icon-parking", label: "icon-parking"},
    {value: "icon-swimming-pool", label: "icon-swimming-pool"},
    {value: "icon-user-alt", label: "icon-user-alt"},
    {value: "icon-user-plus", label: "icon-user-plus"},
    {value: "icon-user-times", label: "icon-user-times"},
    {value: "icon-1", label: "icon-1"},
    {value: "icon-summer", label: "icon-summer"},
    {value: "icon-wireless-antenna", label: "icon-wireless-antenna"},
    {value: "icon-floor", label: "icon-floor"},
    {value: "icon-home-2", label: "icon-home-2"},
    {value: "icon-wireless", label: "icon-wireless"},
    {value: "icon-ironing", label: "icon-ironing"},
    {value: "icon-down-big", label: "icon-down-big"},
    {value: "icon-left-big", label: "icon-left-big"},
    {value: "icon-right-big", label: "icon-right-big"},
    {value: "icon-up-big", label: "icon-up-big"},
    {value: "icon-laundry", label: "icon-laundry"},
    {value: "icon-fax-2", label: "icon-fax-2"},
    {value: "icon-phone-2", label: "icon-phone-2"},
    {value: "icon-tools-2", label: "icon-tools-2"},
    {value: "icon-checkbox-2", label: "icon-checkbox-2"},
    {value: "icon-map", label: "icon-map"},
    {value: "icon-newspaper", label: "icon-newspaper"},
    {value: "icon-note-2", label: "icon-note-2"},
    {value: "icon-picture-2", label: "icon-picture-2"},
    {value: "icon-radio-tower", label: "icon-radio-tower"},
    {value: "icon-video-2", label: "icon-video-2"},
    {value: "icon-down-arrow", label: "icon-down-arrow"},
    {value: "icon-left-arrow", label: "icon-left-arrow"},
    {value: "icon-right-arrow", label: "icon-right-arrow"},
    {value: "icon-up-arrow", label: "icon-up-arrow"},
    {value: "icon-menu-2", label: "icon-menu-2"},
    {value: "icon-cart-2", label: "icon-cart-2"},
    {value: "icon-2", label: "icon-2"},
    {value: "icon-3", label: "icon-3"},
    {value: "icon-5", label: "icon-5"},
    {value: "icon-alarm-clock", label: "icon-alarm-clock"},
    {value: "icon-big-ben", label: "icon-big-ben"},
    {value: "icon-briefcase", label: "icon-briefcase"},
    {value: "icon-sunset", label: "icon-sunset"},
    {value: "icon-star-1", label: "icon-star-1"},
    {value: "icon-speech-bubble-comment", label: "icon-speech-bubble-comment"},
    {value: "icon-small-calendar", label: "icon-small-calendar"},
    {value: "icon-ship-1", label: "icon-ship-1"},
    {value: "icon-school-bus", label: "icon-school-bus"},
    {value: "icon-right-arrow-2", label: "icon-right-arrow-2"},
    {value: "icon-play-button-2", label: "icon-play-button-2"},
    {value: "icon-play-button", label: "icon-play-button"},
    {value: "icon-phone-call", label: "icon-phone-call"},
    {value: "icon-mountains", label: "icon-mountains"},
    {value: "icon-map-marker-point", label: "icon-map-marker-point"},
    {value: "icon-magnifying-glass", label: "icon-magnifying-glass"},
    {value: "icon-location-1", label: "icon-location-1"},
    {value: "icon-kite", label: "icon-kite"},
    {value: "icon-globe-1", label: "icon-globe-1"},
    {value: "icon-cupcake", label: "icon-cupcake"},
    {value: "icon-coconaut-juice", label: "icon-coconaut-juice"},
    {value: "icon-clock-1", label: "icon-clock-1"},
    {value: "icon-tickets", label: "icon-tickets"},
    {value: "icon-tracker", label: "icon-tracker"},
    {value: "icon-user-1", label: "icon-user-1"},
    {value: "icon-quotation-mark", label: "icon-quotation-mark"},
    {value: "icon-earth-globe", label: "icon-earth-globe"},
    {value: "icon-right-arrow-in-a-circle", label: "icon-right-arrow-in-a-circle"},
    {value: "icon-shopping-cart", label: "icon-shopping-cart"},
    {value: "icon-email-1", label: "icon-email-1"},
    {value: "icon-logo", label: "icon-logo"},
    {value: "icon-smartphone", label: "icon-smartphone"},
    {value: "icon-chat", label: "icon-chat"},
    {value: "icon-arrow-pointing-to-right", label: "icon-arrow-pointing-to-right"},
    {value: "icon-user-2", label: "icon-user-2"},
    {value: "icon-arrow-pointing-to-left", label: "icon-arrow-pointing-to-left"},
    {value: "icon-right-arrow-1", label: "icon-right-arrow-1"},
    {value: "icon-resize-full-alt", label: "icon-resize-full-alt"},
    {value: "icon-ambulance", label: "icon-ambulance"},
    {value: "icon-euro", label: "icon-euro"},
    {value: "icon-pound", label: "icon-pound"},
    {value: "icon-dollar", label: "icon-dollar"},
    {value: "icon-yen", label: "icon-yen"},
    {value: "icon-rouble", label: "icon-rouble"},
    {value: "icon-car", label: "icon-car"},
    {value: "icon-taxi", label: "icon-taxi"},
    {value: "icon-bicycle", label: "icon-bicycle"},
    {value: "icon-bus", label: "icon-bus"},
    {value: "icon-ship", label: "icon-ship"},
    {value: "icon-motorcycl", label: "icon-motorcycl"},
    {value: "icon-train", label: "icon-train"},
    {value: "icon-subway", label: "icon-subway"},    
];
