import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import Icon from "../../adminComponents/icon/Icon";
import styles from "./HeaderSearch.module.css";
import Members from './Members';

const HeaderSearch = () => {
  const [input, setInput] = useState("");
  
  const closeSearch = (e) => {
    setInput("");
  }  

  return (
    <>
      <Icon name="search"></Icon>
      <input
        className="form-control border-transparent form-focus-none" type="text" placeholder="Search anything" onChange={(e) => setInput(e.target.value)}
      />
      {input.length > 0 && (
        <div className={styles.search_results + " w-100"}>
          <Col>
            <Row>
              <Col md={11}>
                <p>Search Results for: <strong className="px-2 font-weight-bold">{input}</strong></p>
              </Col>
              <Col md={1} className="text-center">
                <Icon className={styles.two_x} name="cross-circle-fill" onClick={closeSearch}></Icon>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col md={12}>
                <hr />
                <h4>Members</h4>
                <Members name={input} className="w-100" />               
              </Col>              
            </Row>
          </Col>
        </div>
      )}
    </>
  );
};

export default HeaderSearch;
