import React, { useEffect, useLayoutEffect, useState } from "react";
import { Card, Spinner } from "reactstrap";
import {
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Block,
  PreviewAltCard,
  PaginationComponent,
  Button,
  Icon,
} from "../adminComponents/Component";
// import { recentOrderData } from "./OrderData";

const SliderMainData = () => {
  const [SliderMainData, setSliderMainData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  async function getSliderMainData() {
    setLoading(true);
    let url = `api/v1/admin/slider/show`;

    await fetch(`${process.env.REACT_APP_DEFAULT_URL}/${url}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((res) => {
        setSliderMainData(res.slider);
        setLoading(false);
      });
  }

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = SliderMainData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    getSliderMainData();
  }, []);

  useLayoutEffect(() => {
    getSliderMainData();
  }, []);

  return (
    <Card className="card-full" style={{ background: "transparent" }}>
      {/* <div className="card-inner" style={{ "background": "#fff"}}>
        <div className="card-title-group">
          <div className="card-title">
            <h6 className="titles">Survey Data</h6>
          </div>
        </div>
      </div> */}
      {loading && (
        <div className="text-center">
          <Spinner color="dark" />
        </div>
      )}
      <Block>
        <div className="nk-tb-list is-separate is-medium mb-3">
          <DataTableHead>
            <DataTableRow>
              <span>ID.</span>
            </DataTableRow>
            <DataTableRow>
              <span>Image</span>
            </DataTableRow>
            <DataTableRow>
              <span>Title</span>
            </DataTableRow>
            <DataTableRow size="sm">
              <span>Slug</span>
            </DataTableRow>
            {/* <DataTableRow size="md">
            <span>Avatar</span>
          </DataTableRow> */}
            <DataTableRow>
              <span className="d-none d-sm-inline">Button Text</span>
            </DataTableRow>
            <DataTableRow>
              <span>Status</span>
            </DataTableRow>
            <DataTableRow>
              <span>Operations</span>
            </DataTableRow>
          </DataTableHead>
          {currentItems.length > 0
            ? currentItems.map((item, idx) => (
                <DataTableItem key={idx}>
                  <DataTableRow>
                    <span className="tb-lead">
                      <a href="#order" onClick={(ev) => ev.preventDefault()}>
                        {idx + 1}
                      </a>
                    </span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="tb-sub tb-amount w-100">
                      <img
                        src={item.imageSource}
                        className=""
                        style={{ width: "100px" }}
                      />
                    </span>
                  </DataTableRow>
                  <DataTableRow size="sm">
                    <div className="user-card">
                      {/* <UserAvatar className="sm" theme="purple-dim" text={item.country} image={item.avatar}></UserAvatar> */}
                      <div className="user-name">
                        <span className="tb-lead">{item.title}</span>
                      </div>
                    </div>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="tb-sub">{item.slug}</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="tb-sub tb-amount">{item.buttonText}</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="tb-sub tb-amount">{item.status}</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="nk-block-tools-opt pr-2">
                      <Button
                        color="primary"
                        className="btn-icon"
                        // onClick={() => setModal({ add: true })}
                      >
                        <Icon name="pen"></Icon>
                      </Button>
                    </span>
                    <span className="nk-block-tools-opt">
                      <Button
                        color="primary"
                        className="btn-icon"
                        // onClick={() => setModal({ add: true })}
                      >
                        <Icon name="trash"></Icon>
                      </Button>
                    </span>
                  </DataTableRow>
                </DataTableItem>
              ))
            : null}
        </div>
        <PreviewAltCard>
          {currentItems.length > 0 ? (
            <PaginationComponent
              itemPerPage={itemPerPage}
              totalItems={SliderMainData.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            <div className="text-center">
              <span className="text-silent">No data found</span>
            </div>
          )}
        </PreviewAltCard>
      </Block>
    </Card>
  );
};
export default SliderMainData;
