import React from "react";

import AdminContext from "../context/adminContext";

const UseAdminState = (props) => { 

    const adminState = {
        username: "admin",
        password: "onconavigator2022",
    }
    
    return(
        <AdminContext.Provider value={adminState}>
            {props.children}
        </AdminContext.Provider>
    )
}

export default UseAdminState;